@import '../variables.scss';

.Banner {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.logo-container {
    max-width: 400px;
}

.logo-container img {
    max-width: 100%;
    height: auto;
}

.logo-spacer {
    display: none;
}

@media (min-width: $breakpoint-tablet) {
    .logo-spacer {
        display: none;
    }
}

@media (min-width: $breakpoint-desktop) {
    .logo-spacer {
        display: block;
        width: 100%;
        min-width: 200px;
    }
}