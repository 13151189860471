@import '../variables.scss';

.ArtistGallery {
    max-width: 100%;
    grid-area: artist-gallery;
    background-color: $lightyellow-theme-color;
    border-top: 1px solid $red-main-color;
    border-radius: 0 0 10px 10px;
}

.ArtistGallery h3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: $red-main-color;
}

.gallery-flex-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.gallery-photo-container img {
    max-width: 100%;
    vertical-align: middle;
}

@media (min-width: $breakpoint-tablet) {
    .ArtistGallery h3 {
        padding-bottom: 1rem;
    }

    .gallery-flex-container {
        display: flex;
        flex-direction: row;
        border-bottom: 9px;
        overflow: hidden;
    }

    .gallery-photo-container {
        padding: 2rem;
        max-width: 50%;
    }

    .gallery-photo-container img {
        border: 1px solid $red-main-color;
    }
}