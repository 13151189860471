@import '../variables.scss';

.ArtistsMap {
    display: grid;
    grid-template-areas:
        "list"
        "map";
}

.ArtistsMap ul {
    display: grid;
    grid-area: list;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 40px;
}

.ArtistsMap ul button {
    width: 100%;
    height: 40px;
}

.ArtistsMap .OlMap {
    grid-area: map;
}

@media (min-width: $breakpoint-desktop) {
    .ArtistsMap {
        grid-template-areas:
            "map list";
    }

    .ArtistsMap ul {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(autofill, 60px);
        grid-auto-rows: 60px;
    }

    .ArtistsMap ul button {
        height: 60px;
    }

    .ArtistsMap .OlMap {
        min-width: 400px;
    }
}