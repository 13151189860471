.SocialLink {
    height: 2rem;
}

.SocialLink-logo {
    width: 2rem;
    height: 2rem;
}

.SocialLink-url {
    color: red;
    font-size: 1rem;
    line-height: 2rem;
    vertical-align: baseline;
}