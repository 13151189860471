*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style-type: none;
}

$primary-theme-color: #f5c400;
$secondary-theme-color: #00bd8a;
$third-theme-color: #6193c3;
$beige-theme-color: #e4ded2;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}