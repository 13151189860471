@import '../../variables.scss';

.map-container {
    height: 360px;
}

.popup {
    position: absolute;
    min-width: 100px;
    background-color: white;
    padding: 1rem;
    border: 1px solid grey;
    border-radius: 0px 20px 20px 20px;
}

.popup-link {
    padding: 1rem;
    text-decoration: none;
}

.a:link, a:visited {
    color: red;
    text-decoration-line: none;
}

@media (min-width: $breakpoint-tablet) {
    .map-container {
        width: auto;
        height: 768px;
    }

    .popup {
        min-width: 200px;
    }
}