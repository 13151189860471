@import '../variables.scss';

.ArtistsList {
    margin: 0 auto;
    width: fit-content;
}

.ArtistsList ul {
    display: grid;
    grid-template-columns: 1fr;
    list-style-type: none;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
}

.ArtistsList ul li {
    padding: 1rem;
}

.ArtistsList ul li a {
    color: $red-main-color;
    text-decoration: none;
}

.ArtistsList ul li a:visited {
    color: $red-main-color;
}

.ArtistsList .artist-container {
    display: block;
}

.ArtistsList img {
    width: 190px;
    height: 190px;
}

.ArtistsList .artist-container span {
    display: block;
    width: 190px;
}

@media (min-width: $breakpoint-tablet) {
    .ArtistsList ul {
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: $breakpoint-desktop) {
    .ArtistsList ul {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (min-width: $breakpoint-widescreen) {
    .ArtistsList ul {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}