$breakpoint-tablet: 560px;
$breakpoint-desktop: 960px;
$breakpoint-widescreen: 1300px;

$green-main-color: #4d8d36;
$red-main-color: #d21e00;
$yellow-theme-color: #f5c400;
$green-theme-color: #00bd8a;
$blue-theme-color: #6193c3;
$beige-theme-color: #e4ded2;
$lightyellow-theme-color: #fdf9e6;
$lightgreen-theme-color: #648b56;
