@import '../variables.scss';

.Sponsors {
    width: 100%;
    background-color: #82b159;
    border-radius: 0 0 7px 7px;
}

.thankssponsors-container {
    text-align: left;
}

.thankssponsors-container img {
    width: 90%;
    max-width: 300px;
}

.sponsors-logo-list {
    display: flex;
    flex-direction: column;
}

.sponsors-logo-list img {
    max-height: 8em;
    max-width: 100%;
}

.sponsors-text-list {
    padding-bottom: 1em;
}

@media (min-width: $breakpoint-tablet) {
    .sponsors-logo-list {
        display: grid;
        grid-template-columns: auto auto auto;
    }

    .sponsors-text-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}

@media (min-width: $breakpoint-desktop) {
    .sponsors-logo-list {
        display: grid;
    }
}