@import '../variables.scss';

.NavBar ul {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0;
    list-style-type: none;
    background-color: white;
}

.NavBar ul li {
    width: 50%;
    border: 1px solid $red-main-color;
    font-size: larger;
}

.NavBar ul li a {
    width: 100%;
    color: $red-main-color;
    text-decoration: none;
}

.NavBar .navbar-linkbox {
    padding: 0.8rem;
}

/* .NavBar ul li {
    margin-left: 6rem;
    margin-right: 6rem;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    border: 1px solid $red-main-color;
    border-radius: 4px;
    font-size: larger;
}

.NavBar ul li a {
    width: 100%;
    color: $red-main-color;
    text-decoration: none;
}

.NavBar .navbar-linkbox {
    padding: 0.7rem;
} */

.NavBar ul li a:visited {
    color: $red-main-color;
}

@media (min-width: $breakpoint-tablet) {
    .NavBar ul {
        flex-direction: row;
    }

    .NavBar ul li {
        width: 128px;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0.2rem;
        margin-bottom: 0.2rem;
        border: none;
    }
}