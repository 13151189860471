@import '../variables.scss';

.NavBarBottom ul {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0;
    list-style-type: none;
}

.NavBarBottom ul li {
    width: 50%;
    border: 1px solid $red-main-color;
    background-color: white;
    font-size: larger;
}

.NavBarBottom ul li.left {
    border-radius: 0 15px 0 0;
}

.NavBarBottom ul li.middle {
    border-radius: 15px 15px 0 0;
}

.NavBarBottom ul li.right {
    border-radius: 15px 0 0 0;
}

.NavBarBottom ul li a {
    width: 100%;
    color: $red-main-color;
    text-decoration: none;
    background-color: white;
}

.NavBarBottom .navbar-linkbox {
    padding: 0.8rem;
}

.NavBarBottom ul li a:visited {
    color: $red-main-color;
}
