@import './variables.scss';

.App {
  text-align: center;
  margin: 0;
}

.navbar-topper {
  display: none;
}

.navbar-bottomer {
  display: display;
  width: 100%;
  position:fixed;
  bottom: 0;
}

.navbar-bottomer-fixer {
  display: display;
  height: 5rem;
}

@media (min-width: $breakpoint-desktop) {
  .App {
    width: 70%;
    min-width: 200px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .navbar-topper {
    display: block;
  }
  
  .navbar-bottomer {
    display: none;
  }

  .navbar-bottomer-fixer {
    display: none;
  }
}