@import '../variables.scss';

.pageItem-body p {
    text-align: center !important;
    font-size: large;
}

.atelier-section {
    margin: auto;
    width: fit-content;
}

.atelier-list {
    text-align: left;
    font-size: large;
    padding: 0;
    width: fit-content;
}

.atelier-list > li {
    border-left: solid 5px $red-main-color;
    margin-top: 0.6em;
    margin-bottom: 0.6em;
    padding-left: 0.6em;
}

.atelier-list > li a {
    color: $red-main-color;
}

.atelier-list > li a:visited {
    color: $red-main-color 0.8;
}

.atelier-list > li ul {
    display: flex;
    list-style-type: none;
    flex-direction: row;
}

.atelier-list span {
    font-weight: 700;
}

.handkraft-list > li {
    font-weight: 600;
}

.konstlandskapetOland-color {
    color: rgb(109, 75, 189);
}

.poster-link img {
    width: 14em;
    border: 3px double $red-main-color;
}

.sponsors {
    background-color: #82b159;
    border-radius: 0 0 7px 7px;
}

@media (max-width: $breakpoint-tablet) {
    .flowerImage {
        display: none;
    }
}

@media (min-width: $breakpoint-desktop) {
    .news-body p {
        text-align: center !important;
        font-size: larger;
        font-weight: 600;
    }
    
    .flowerImage {
        max-width: 400px;
    }
}