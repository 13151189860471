@import './variables.scss';

.pageItem {
    padding: 0;
}

.pageItem-header {
    margin-right: 0;
    margin-bottom: 1rem;
}

.pageItem-header h2 {
    margin-top: 20px;
    color: $red-main-color;
    text-transform: uppercase;
}

.pageItem-body {
    padding: 14px;
    padding-top: 2px;
    line-height: 1.6rem;
}

.pageItem-body p {
    margin: 1rem;
}

.pageItem-body .major-text {
    font-size: larger;
}

@media (min-width: $breakpoint-desktop) {
    .page {
        border: 1px solid $green-main-color;
        border-radius: 9px;
    }
}